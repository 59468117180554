import { createReducer, on, Action } from "@ngrx/store";
import * as cargasActions from './cargas.actions';
import { Carga } from 'adgas_shared';
import { initialState, adapter, CargasState } from './cargas.state';
import { Update } from '@ngrx/entity';
const reducer = createReducer(
    initialState,
    on(
        cargasActions.LoadCargas,
        cargasActions.UpdateCarga,
        cargasActions.CreateCarga,
        cargasActions.DeleteCarga,
        state => ({...state, loading: true, loaded: false})
    ),
    on(
        cargasActions.LoadCargasSuccess,
        ( state,{cargas} ) => {
            return adapter.setAll(
                cargas,
                {...state, loading: false, loaded: true}
            );
        }
    ),
    on(
        cargasActions.UpdateCargaFailed,
        cargasActions.LoadCargasFailed,
        cargasActions.CreateCargaFailed,
        cargasActions.DeleteCargaFailed,
        ( state, {error} ) => ({...state, loading: false, loaded: false})
    ),
    on(
        cargasActions.UpdateCargaSuccess,
        (state, {carga}) => {
            const cargaUpdate: Update<Carga> = {
                id: carga._id,
                changes:{
                    ...carga
                }
            };
            return adapter.updateOne(
                cargaUpdate,
                {...state, loading: false, loaded: true}
            )
        }
    ),
    on(
        cargasActions.CreateCargaSuccess,
        (state, {carga}) => {
            return adapter.addOne(
                carga,
                {...state, loading:false, loaded: true}
            )
        }
    ),
    on(
        cargasActions.DeleteCargaSuccess,
        (state, {_id}) => {
            return adapter.removeOne(
                _id,
                {...state, loading: false, loaded: true}
            )
        }
    )
);

export function cargasReducer(state: CargasState | undefined, action: Action){
    return reducer(state, action);
}