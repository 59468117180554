// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    hmr       : false,
    api_url   : 'https://devadg.com/api',
     /* api_url   : 'http://localhost:3001/api', */
     fotos_url_cdn : 'https://cdn.devadg.com/',
     fotos_url: 'https://adgas.sfo3.digitaloceanspaces.com/',
     firebase: {
        apiKey: "AIzaSyBdJ0SJqbZLFOYz1wzZbdmbUvbB7wnYzTg",
        authDomain: "adgas-6b5b3.firebaseapp.com",
        databaseURL: "https://adgas-6b5b3.firebaseio.com",
        projectId: "adgas-6b5b3",
        storageBucket: "adgas-6b5b3.appspot.com",
        messagingSenderId: "881553159897",
        appId: "1:881553159897:web:291cb036cec86d8f4d30c8"
    },
    do:{
        accessKeyId: "EJ6RYNB5SBEUZDC64C27",
        secretAccessKey: "79JnpsifbrHan6KkqQTIWrbwG9EsAn2Ow6F1DgZPNWU",
    },
    mapbox: {
        accessToken: 'pk.eyJ1IjoiYWxsYmVydG8iLCJhIjoiY2l4aDBmdWljMDA1MTJ0bXQ4NWo2cWJ2ayJ9.0peO5-06llt1MXy18-v_zg'
    },
    SOCKET_ENDPOINT: 'https://devadg.com',
   /* SOCKET_ENDPOINT   : 'http://localhost:3000', */
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
