import { FuseNavigation } from '@fuse/types';

export const navigationCobranza: FuseNavigation[] = [
    {
        id       : 'applications',
        title    : 'Aplicaciones',
        translate: 'NAV.APPLICATIONS',
        type     : 'group',
        children : [
            {
                id       : 'facturacion',
                title    : 'Facturación',
                type     : 'item',
                icon     : 'credit_card',
                url: '/facturacion'
            },
            {
                id       : 'pagos',
                title    : 'Pagos',
                type     : 'item',
                icon     : 'credit_card',
                url: '/pagos'
            },
        ]
    }
    
];
