import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Carga } from 'adgas_shared';

export interface CargasState extends EntityState<Carga> {
  loading: boolean;
  loaded: boolean;
}

export const adapter: EntityAdapter<Carga> =
  createEntityAdapter<Carga>({
    // custom _id as selectedId
    selectId: cargaRef => cargaRef._id
  });

export const initialState: CargasState =
  adapter.getInitialState({
    loading: false,
    loaded: false
  });
