import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Carga } from 'adgas_shared';
import { of } from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
} from 'rxjs/operators';

import {
  Actions,
  createEffect,
  ofType,
} from '@ngrx/effects';

import { ApiService } from '../../services/api.service';
import * as cargasAction from './cargas.actions';

@Injectable()
export class CargasEffects {
    constructor(
        private _actions$: Actions,
        private _snackBar: MatSnackBar,
        private _apiService: ApiService,
    ) { }

    load$ = createEffect(() =>
    this._actions$.pipe(
        ofType(cargasAction.LoadCargas),
        mergeMap( ({pipaId}) => 
            this._apiService.getCargas({pipaId}).pipe(
                map( (cargas:Carga[]) => cargasAction.LoadCargasSuccess({cargas})),
                catchError((httpError: HttpErrorResponse) => {
                    this._snackBar.open(`Can't load the API. Please verify your connection`, 'Ok', {
                      duration: 3000,
                    });
                    return of(cargasAction.LoadCargasFailed({ error: httpError.message }));
                })
            )
        )
    )
  );

  create$ = createEffect(() =>
    this._actions$.pipe(
        ofType(cargasAction.CreateCarga),
        mergeMap( ({carga}) => 
            this._apiService.createCarga(carga).pipe(
                map( (carga:Carga) => cargasAction.CreateCargaSuccess({carga})),
                catchError((httpError: HttpErrorResponse) => {
                    this._snackBar.open(`Can't load the API. Please verify your connection`, 'Ok', {
                      duration: 3000,
                    });
                    return of(cargasAction.CreateCargaFailed({ error: httpError.message }));
                })
            )
        )
    )
  );

  update$ = createEffect(() =>
  this._actions$.pipe(
      ofType(cargasAction.UpdateCarga),
      mergeMap( ({carga}) => 
        this._apiService.updateCarga(carga).pipe(
            map( carga => cargasAction.UpdateCargaSuccess({carga}) ),
            catchError((httpError: HttpErrorResponse) => {
                this._snackBar.open(`Can't load the API. Please verify your connection`, 'Ok', {
                  duration: 3000,
                });
                return of(cargasAction.UpdateCargaFailed({ error: httpError.message }));
            })
        )
      )
    )
  )

  delete$ = createEffect(() =>
  this._actions$.pipe(
      ofType(cargasAction.DeleteCarga),
      mergeMap( ({_id}) => 
        this._apiService.deleteCarga(_id).pipe(
            map( _id => cargasAction.DeleteCargaSuccess({_id}) ),
            catchError((httpError: HttpErrorResponse) => {
                this._snackBar.open(`Can't load the API. Please verify your connection`, 'Ok', {
                  duration: 3000,
                });
                return of(cargasAction.DeleteCargaFailed({ error: httpError.message }));
            })
        )
      )
    )
  )
    
}