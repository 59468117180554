import { Injectable } from '@angular/core';

import { Carga } from 'adgas_shared';
import { Observable } from 'rxjs';

import {
  select,
  Store,
} from '@ngrx/store';

import * as cargasActions from './cargas.actions';
import * as cargasSelector from './cargas.selectors';
import { CargasState } from './cargas.state';

@Injectable()
export class CargasFacade {

  cargas$: Observable<Carga[]> = this.store.pipe(select(cargasSelector.selectAll));

  constructor(private store: Store<CargasState>) {}

  load(pipaId: string): void {
    this.store.dispatch(cargasActions.LoadCargas({pipaId}));
  }

  update(carga: Carga): void {
    this.store.dispatch(cargasActions.UpdateCarga({ carga }));
  }
  create(carga: Carga): void {
    this.store.dispatch(cargasActions.CreateCarga({ carga }));
  }

  delete(_id: string): void {
    this.store.dispatch(cargasActions.DeleteCarga({ _id }));
  }

}
