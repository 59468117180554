import {
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';

import {
  adapter,
  PipasState,
} from './pipas.state';

const getPipasState = createFeatureSelector<PipasState>('pipas');

export const {
  selectIds,
  selectEntities,
  selectAll
} = adapter.getSelectors(getPipasState);

export const getPipaById = createSelector(
    selectEntities,
    (entities, {pipaId}) => {
        return entities[pipaId];
    }
);
