import { FuseNavigation } from "@fuse/types";

export const navigation: FuseNavigation[] = [

    {
        id       : 'applications',
        title    : 'Aplicaciones',
        translate: 'NAV.APPLICATIONS',
        type     : 'group',
        children : [
        {
            id: 'plantas',
            title: 'Plantas',
            type: 'item',
            icon: 'business',
            url: '/plantas'
        },
        {
            id: 'users',
            title: 'Usuarios',
            type: 'item',
            icon: 'person_outline',
            url: '/users'
        },
        {
            id       : 'pipas',
            title    : 'Pipas',
            type     : 'item',
            icon     : 'directions_bus',
            url: '/pipas'
        },
        {
            id       : 'almacenes',
            title    : 'Almacenes',
            type     : 'collapsable',
            icon     : 'location_city',
            children: [
                {
                    id:'administracion',
                    title: 'Administración',
                    type: 'item',
                    url: '/almacenes/administracion',
                    exactMatch: true
                },
                {
                    id:'suministro',
                    title: 'Suministro',
                    type: 'item',
                    url: '/almacenes/suministro',
                    exactMatch:true
                }
            ]
        },
      /*   {
            id       : 'carga-manual',
            title    : 'Carga Pipa Manual',
            type     : 'item',
            icon     : 'battery_full',
            url: '/carga-manual'
        }, */
        {
            id       : 'facturacion',
            title    : 'Facturación',
            type     : 'item',
            icon     : 'credit_card',
            url: '/facturacion'
        },
    ]
    },
    
];
