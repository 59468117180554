import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-image-picker',
  templateUrl: './image-picker.component.html',
  styleUrls: ['./image-picker.component.css']
})
export class ImagePickerComponent implements OnInit {

  filePristine = true;

  @ViewChild('inputFile', { static: true }) inputFile: ElementRef;
  @Output() filechange = new EventEmitter<{ file: File, customSrc: string }>();
  @Output() fileremoved = new EventEmitter<boolean>();
  @Input() text: string = 'Elegir archivs'
  @Input() file: File;
  @Input() customSrc: string;

  constructor() { }

  ngOnInit(): void { }

  fileChange(files: File[]): void {
    this.filePristine = false;
    this.file = files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.customSrc = reader.result as string;
      this.filechange.emit({ file: this.file, customSrc: this.customSrc });
    };
    reader.readAsDataURL(files[0]);
  }

  removeImage() {
    this.inputFile.nativeElement.value = '';
    this.file = undefined;
    this.customSrc = undefined;
    this.fileremoved.emit(true);
   }

}
