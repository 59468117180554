import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';

import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';

import { ComponentsModuleModule } from '../../components/components.module';
import { AlmacenesComponent } from './almacenes.component';

const routes = [
    {
        path     : '',
        component: AlmacenesComponent
    },
    {
        path: 'suministro',
        component: AlmacenesComponent
    },
    {
        path: 'administracion',
        component: AlmacenesComponent
    }
];

@NgModule({
    declarations: [
        AlmacenesComponent,
    ],
    imports: [
        RouterModule.forChild(routes),
        MatIconModule,
        MatPaginatorModule,
        MatTableModule,
        MatButtonModule,
        MatDialogModule,
        TranslateModule,
        MatFormFieldModule,
        MatToolbarModule,
        MatInputModule,
        MatSortModule,
        MatMenuModule,
        MatSlideToggleModule,
        FuseSharedModule,
        MatSelectModule,
        MatOptionModule,
        ComponentsModuleModule,
        MatDatepickerModule
    ],
    exports: [
        AlmacenesComponent
    ]
})

export class AlmacenesModule
{
}
