import { Carga } from 'adgas_shared';

import {
  createAction,
  props,
} from '@ngrx/store';

/** Load Cargas **/
export const LoadCargas = createAction(
    '[Cargas/API] Load Cargas',
    props<{pipaId: string}>()
);

export const LoadCargasSuccess = createAction(
    '[Cargas/API] Load Cargas Success',
    props<{cargas: Carga[]}>()
);

export const LoadCargasFailed = createAction(
    '[Cargas/API] Load Cargas Failed',
    props<{error:any}>()
)
/** Create Cargas **/
export const CreateCarga = createAction(
    '[Cargas/API] Create Carga',
    props<{carga: Carga}>()
);

export const CreateCargaSuccess = createAction(
    '[Cargas/API] Create Carga Success',
    props<{carga: Carga}>()
);

export const CreateCargaFailed = createAction(
    '[Cargas/API] Create Carga Failed',
    props<{error:any}>()
)
/** Update Cargas **/
export const UpdateCarga = createAction(
    '[Cargas/API] Update Carga',
    props<{carga: Carga}>()
);

export const UpdateCargaSuccess = createAction(
    '[Cargas/API] Update Carga Success',
    props<{carga: Carga}>()
);

export const UpdateCargaFailed = createAction(
    '[Cargas/API] Update Carga Failed',
    props<{error:any}>()
)
/** Delete Cargas **/
export const DeleteCarga = createAction(
    '[Cargas/API] Delete Carga',
    props<{_id: string}>()
);

export const DeleteCargaSuccess = createAction(
    '[Cargas/API] Delete Carga Success',
    props<{_id: string}>()
);

export const DeleteCargaFailed = createAction(
    '[Cargas/API] Delete Carga Failed',
    props<{error:any}>()
)